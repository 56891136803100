<template>
  <div class="seaDetail-container">
    <el-drawer
      class="seaDetail-drawer"
      title="进账详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div class="first">
            <div class="title">
              <span class="first-tit">{{ tableRow.orderNo }}</span>
              <span
                v-if="tableRow.financialStatus == 'pending'"
                class="tit-btn"
                style="color: #F98787;border: 1px solid #F98787;"
              >
                待销售催款
              </span>
              <span
                v-if="tableRow.financialStatus == 'confirming'"
                class="tit-btn"
                style="color: #F09009;border: 1px solid #f09009;"
                >待财务确认
              </span>
              <span
                v-if="tableRow.financialStatus == 'complete'"
                class="tit-btn"
                style="color: #1890FF;border: 1px solid #1890FF;"
                >交易完成
              </span>
            </div>
            <div class="first-con1">
              <span class="con-margin"
                >订单名称：{{ tableRow.orderName || "--" }}</span
              >
              <span class="con-margin"
                >客户名称：{{ tableRow.customerName || "--" }}</span
              >
              <span class="con-margin"
                >服务时间：{{ tableRow.sericeTime || "--" }}</span
              >
            </div>
            <div class="first-con2">
              <span class="con-margin"
                >销售负责人：{{ tableRow.createName || "--" }}</span
              >
              <span class="con-margin"
                >订单总金额：{{ (tableRow.price&&Number(tableRow.price).toFixed(2)) || "--" }}</span
              >
              <span class="con-margin"
                >订单原始价格：{{ (tableRow.originalPrice&&Number(tableRow.originalPrice).toFixed(2)) || 0 }}</span
              >
              <span class="con-margin"
                >订单调价价格：{{ (tableRow.differencePrice&&Number(tableRow.differencePrice).toFixed(2)) || 0 }}</span
              >
              
            </div>
            <div class="first-con2">
              <span class="con-margin"
                >订单收款方式：{{ tableRow.paymentMethods || "--" }} </span >
              <span class="con-margin"
                >已收金额：{{ (tableRow.collectionAmount&&Number(tableRow.collectionAmount).toFixed(2)) || 0 }}</span
              >
              <span class="con-margin"
                >未收金额：{{ (tableRow.uncollectionAmount&&Number(tableRow.uncollectionAmount).toFixed(2)) || 0 }}</span
              >
              
            </div>
            <div class="first-con3">
              <span class="con-margin">备注：{{ tableRow.note || "--" }}</span>
            </div>
          </div>


          <el-tabs v-model="activeTab" @tab-click="handleClick">
            <el-tab-pane v-if="tableData" label="进账信息" name="1">
              <!-- 进账信息 -->
              <div class="data-content">
            <!-- <div class="data-title">进账信息</div> -->
            <div class="detail-content">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column
                  prop="code"
                  label="流水编号"
                  width="120"
                  :formatter="tableColumn"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="() => incomeInfo(scope.row)"
                      >{{ scope.row.code }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="amount"
                  label="金额"
                  width="160"
                  :formatter="tableColumn"
                >
                  <template slot-scope="scope">
                    <span class="amount">+{{ scope.row.amount }}</span> <br />
                    <!-- <span v-if="scope.row.financialStatus == 'historyFlow'" class="money">历史收款</span> -->
                    <span
                      :class="
                        scope.row.financialStatus == 'historyFlow'
                          ? 'money'
                          : scope.row.financialStatus == 'receivable'
                          ? 'money2'
                          : 'money3'
                      "
                      >{{
                        scope.row.financialStatus == "historyFlow"
                          ? "历史收款"
                          : scope.row.financialStatus == "receivable"
                          ? "当月应收"
                          : "预付款"
                      }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="recordType"
                  label="收支方式"
                  :formatter="tableColumn"
                  width="120px"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.recordType == 'bankCard'"
                      >银行卡</span
                    >
                    <span v-else-if="scope.row.recordType == 'alipay'"
                      >支付宝</span
                    >
                    <span v-else-if="scope.row.recordType == 'wechat'"
                      >微信</span
                    >
                    <span v-else-if="scope.row.recordType == 'cash'">现金</span>
                    <span v-else-if="scope.row.recordType == 'corporateAccount'">对公账户</span>
                    <span v-else-if="scope.row.recordType == 'generalAccount'">对公账户（总）</span>
                    <span v-else-if="scope.row.recordType == 'publicAccount'">对公账户（分）</span>
                    <span v-else-if="scope.row.recordType == 'taobao'">淘宝</span>
                    <span v-else-if="scope.row.recordType == 'tiktok'">抖音</span>
                    <span v-else-if="scope.row.recordType == 'pinduoduo'">拼多多</span>
                    <span v-else-if="scope.row.recordType == 'other'">其他</span>
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  width="150"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  width="180"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="confirmRecordTime"
                  label="确认到账时间"
                  width="120"
                  :formatter="tableColumn"
                >
                  <template slot-scope="scope">
                    <span>{{scope.row.confirmRecordTime ? scope.row.confirmRecordTime : '--'}}</span>
                  </template>
                  
                </el-table-column>
                <el-table-column
                  prop="confirmedName"
                  label="审批人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="操作" width="110" fixed="right">
                  <template slot-scope="scope">
                    <!-- {{scope.row}} -->
                    <!-- 判断一下 按钮显示 -->
                    <el-button
                      v-if="scope.row.deleteFlag == 1"
                      plain
                      size="mini"
                      disabled
                      >已作废</el-button
                    >
                    <el-button
                      v-else-if="
                        scope.row.deleteFlag == 0 &&
                          scope.row.status == 'unconfirmed'
                      "
                      type="primary"
                      size="mini"
                      @click="
                        () => {
                          conIncome(scope.row);
                        }"
                      >确认到账</el-button
                    >
                    <!-- <el-popconfirm
                      v-if="scope.row.status == 'unconfirmed'"
                      title="确认到账？"
                      @confirm="
                        () => {
                          conIncome(scope.row);
                        }
                      "
                    >
                      <el-button type="primary" slot="reference" size="mini"
                        >确认到账</el-button
                      >
                    </el-popconfirm> -->
                    <el-button v-else class="btned" plain size="mini" disabled
                      >已到账</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br/>
              <el-pagination
                v-if="pagination.total > 5"
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="params.pageSize"
                :current-page="params.page"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"
              >
              </el-pagination>
            </div>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="tableData2&&tableData2.length" label="结算信息" name="2">
              <!-- 平台派单----结算信息 -->
              <div class="data-content" v-if="tableData2&&tableData2.length">
            <div class="data-title">结算信息</div>
            <div class="detail-content">
              <el-table
                class="common-table"
                :data="tableData2"
                :fit="true"
              >
                <el-table-column
                  prop="transactionName"
                  label="收款人"
                  width="90px"
                >
                </el-table-column>
                <el-table-column prop="amount" label="金额">
                </el-table-column>
                <el-table-column label="结算类型">
                  供应商结算
                </el-table-column>
                <el-table-column
                  prop="recordTypeName"
                  label="结算方式"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="transactionAccount"
                  label="收款账户"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="transactionPhone"
                  label="联系方式"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="confirmedName"
                  label="审批人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="confirmRecordTime"
                  label="确认到账时间"
                  :formatter="tableColumn"
                  width="120px"
                >
                <template slot-scope="scope">
                    <span>{{scope.row.confirmRecordTime ? formatDateTimer(scope.row.confirmRecordTime) : '--'}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="110px">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.deleteFlag == 1"
                      plain
                      size="mini"
                      disabled
                      >已确认</el-button
                    >
                    <el-popconfirm
                      v-else-if="
                        scope.row.deleteFlag == 0&&
                          scope.row.status == 'unconfirmed'
                      "
                      title="确认到账？"
                      @confirm="
                        () => {
                          conIncome(scope.row);
                        }
                      "
                    >
                      <el-button type="primary" slot="reference" size="mini"
                        >确认到账</el-button
                      >
                    </el-popconfirm>
                    <!-- <el-button
                      v-else-if="
                        scope.row.deleteFlag == 0&&
                          scope.row.status == 'unconfirmed'
                      "
                      type="primary"
                      size="mini"
                      @click="
                        () => {
                          conIncome(scope.row);
                        }"
                      >确认到账</el-button
                    > -->
                    <el-button v-else class="btned" plain size="mini" disabled
                      >已到账</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="'订单调价(' + paginationChange.total + ')'" name="4">
              <el-table v-loading="loadingOrder" element-loading-text="数据较多，拼命加载中..." class="common-table" :data="changeTableData">
                <el-table-column prop="orderNo" label="订单调价编号" width="140px" fixed>
                  <template slot-scope="scope">
                    <el-button @click="() => { openNewWin(`/work-order/approve?workOrderName=${scope.row.workOrderName}&workOrderId=${scope.row.workOrderId}`) }" type="text" size="small">{{ scope.row.orderNo || "--" }}</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="订单应收金额" prop="orderPrice" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="改价类型" width="120px"> 
                  <template slot-scope="scope">
                    <div v-if="scope.row.type == 0">加班费</div>
                    <div v-else-if="scope.row.type == 1">咨询量差价</div>
                    <div v-else-if="scope.row.type == 2">增减坐席/人力</div>
                    <div v-else-if="scope.row.type == 3">服务扣款</div>
                    <div v-else-if="scope.row.type == 5">服务对接群</div>
                    <div v-else-if="scope.row.type == 4">其他</div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <el-table-column label="店铺" prop="shopNames" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="时间段" width="100px">
                  <template slot-scope="scope">
                      <span>{{scope.row.startTime ? `${DateTransform(scope.row.startTime)}`: '-- ~ '}} </span>
                      <span>{{scope.row.endTime ? `${DateTransform(scope.row.endTime)}`: '--'}}</span>
                    <!-- <div style="white-space: pre-line">{{`${DateTransform(scope.row.startTime)} \n ${DateTransform(scope.row.endTime)}`}}</div> -->
                  </template>
                </el-table-column>
                <el-table-column label="调价金额" prop="changeMoneny" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="改价结果" prop="result" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="改价说明" prop="remark" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="审批状态" width="90px">
                  <template slot-scope="scope">
                    <div style="color: #52C419" v-if="scope.row.workStatus == 1">处理中</div>
                    <div style="color: #1890ff" v-else-if="scope.row.workStatus == 2">已完成</div>
                    <div style="color: #FF4D4F" v-else-if="scope.row.workStatus == 3">拒绝</div>
                    <div style="color: #aaa" v-else-if="scope.row.workStatus == 4">撤回</div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <el-table-column label="我的调价待办" width="140px">
                  <template slot="header">
                    <el-tooltip class="item" effect="dark" content="调价审批当前步骤要我待办" placement="bottom">
                      <div style="display: inline-block;">
                        <span>我的调价待办</span> 
                        <img
                          src="~@/assets/images/question.png"
                          class="tit-img"
                        />
                      </div>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <span v-if="scope.row.dealFlag == 1" style="color: #F09009;">是</span>
                    <span v-else style="color: #AAAAAA;">否</span>
                  </template>
                </el-table-column>
                <el-table-column label="创建人" prop="createName" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="创建人" prop="createName" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="创建时间" width="140px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">{{scope.row.createTime || '--'}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="最后处理时间" width="140px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">{{formatDateTimer(scope.row.groupid)}}</div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunChange(e, 'pageSize')"
                @current-change="(e) => pageFunChange(e)"
                style="text-align: center"
                :page-sizes="paginationChange.pageSizes"
                :page-size="changeParams.pageSize"
                :current-page="changeParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="paginationChange.total"
              ></el-pagination>
              <br />
            </el-tab-pane>
            <el-tab-pane v-if="platform !='平台派单'" label="基本信息" name="3">
              <div class="base">
            <div class="data-title">基本信息</div>
            <div>
              <span class="baseinfo"
                >负责人:
                <span class="common-clr-0a6">{{
                  (adminInfo && adminInfo.nickName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >负责人所属部门:
                <span class="common-clr-0a6">{{
                  (adminInfo && adminInfo.userDepartName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >创建人:
                <span class="common-clr-0a6">{{
                  (adminInfo && adminInfo.nickName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >创建时间:
                <span class="common-clr-0a6">{{
                  (OrderInfo && OrderInfo.createTime) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >最后修改人:
                <span class="common-clr-0a6">{{
                  (OrderInfo && OrderInfo.createName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >最后修改时间:
                <span class="common-clr-0a6">{{
                  (OrderInfo && OrderInfo.updateTime) || "--"
                }}</span></span
              >
            </div>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="platform =='平台派单'" label="基本信息" name="3">
              <div class="base">
            <div class="data-title">基本信息</div>
            <div>
              <span class="baseinfo"
                >负责人:
                <span class="common-clr-0a6">{{
                  (platInfo && platInfo.noticeName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >负责人所属部门:
                <span class="common-clr-0a6">{{
                  (platInfo && platInfo.noticeDepartName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >创建人:
                <span class="common-clr-0a6">{{
                  (platInfo && platInfo.createName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >创建时间:
                <span class="common-clr-0a6">{{
                  (platInfo && platInfo.createTime) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >最后修改人:
                <span class="common-clr-0a6">{{
                  (platInfo && platInfo.updateName) || "--"
                }}</span></span
              >
              <span class="baseinfo"
                >最后修改时间:
                <span class="common-clr-0a6">{{
                  (platInfo && platInfo.updateTime) || "--"
                }}</span></span
              >
            </div>
          </div>
            </el-tab-pane>
            
          </el-tabs>

          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                订单合同
              </div>
              <div class="tip" v-if="platform">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">
                  上传附件<input
                    @input="
                      (e) => {
                        uploadFile(e);
                      }
                    "
                    class="uploadBtn-input"
                    type="file"
                  />
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="filesList-container">
              <div
                v-for="(item, index) in fileList"
                :key="index"
                class="fileItem"
              >
                <common-picture
                  :fileUrl="item.url"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.url.split('.')[item.url.split('.').length - 1]
                    )
                      ? 'imgText'
                      : 'file'"
                  :fileName="item.name"
                ></common-picture>
                <!-- <div class="fileItem-name">{{ item.name }}</div> -->
                <div class="fileItem-btn-Container">
                  <!-- <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.url);
                      }
                    "
                  >
                    查看
                  </div> -->
                  <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.url);
                      }"
                  >
                    下载
                  </div>
                  <!-- <el-popconfirm
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <el-button type="text" size="small" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm> -->
                </div>
              </div>
            </div>
            <div v-else-if="fileList2 && fileList2.length" class="filesList-container">
              <div
                v-for="(item, index) in fileList2"
                :key="index"
                class="fileItem"
              >
                <common-picture
                  :fileUrl="item.filedUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.filedUrl.split('.')[item.filedUrl.split('.').length - 1]
                    )
                      ? 'imgText'
                      : 'file'"
                  :fileName="item.filedName"
                ></common-picture>
                <!-- <div class="fileItem-name">{{ item.name }}</div> -->
                <div class="fileItem-btn-Container">
                  <!-- <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.url);
                      }
                    "
                  >
                    查看
                  </div> -->
                  <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.filedUrl);
                      }"
                  >
                    下载
                  </div>
                  <el-popconfirm
                    v-if="fileList2 && fileList2.length"
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <el-button type="text" size="small" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div
              v-else-if="!fileList || !fileList.length"
              class="filesList-container"
            >
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <el-tabs
            style="height: 100%;display: flex;flex-direction: column;"
            v-model="activeName"
          >
            <el-tab-pane label="工作记录" name="about">
              <!-- <work-log :followRecordList="followRecordList"></work-log> -->
              <common-work-log
                :followRecordList="followRecordList"
              ></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <!-- 进账信息详情 -->
    <el-dialog
      v-dialogDrag
      title="进账详情"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
    >
      <div class="income-one">
        <div>
          <span class="one-tit">订单总额：</span
          >{{ details.orderPrice || "--" }} <span class="one-unit">元</span>
        </div>
        <div>
          <span class="one-tit">应收金额：</span
          >{{ details.uncollectionPrice || 0 }}
          <span class="one-unit">元(未收金额)</span>
        </div>
        <div>
          <span class="one-tit">订单服务时间：</span
          >{{ details.orderStartDate || "--" }}~{{
            details.orderEndDate || "--"
          }}
          <span class="one-unit">({{ details.orderDays || "--" }})天</span>
        </div>
        <div>
          <span class="one-tit">实收金额：</span
          >{{ details.collectionAmount || "--" }}
          <span class="one-unit">元</span>
        </div>
        <div>
          <span class="one-tit">实收对应时间：</span
          >{{ details.serviceStartTime || "--" }}~{{
            details.serviceEndTime || "--"
          }}
          <span class="one-unit">({{ details.effectiveDays || "--" }})天</span>
        </div>
        <div>
          <span class="one-tit">收款方式：</span
          >{{
            details.recordType == "bankCard"
              ? "银行卡"
              : (details.recordType == "alipay"
                  ? "支付宝"
                  : details.recordType == "wechat"
                  ? "微信"
                  : details.recordType == "cash"
                  ? "现金"
                  : "其他") || "--"
          }}
        </div>
        <div>
          <span class="one-tit">通知人：</span>{{ details.noticeNames || "--" }}
        </div>
        <div>
          <span class="one-tit">备注：</span>{{ details.remark || "--" }}
        </div>
        <div style="display: flex;align-items: baseline;">
          <span class="one-tit2 one-bottom box">附件：</span>
          <div class="filelist">
            <div
              v-for="(item, i) in details.fileUrls"
              :key="i"
              style="display: flex;align-items: center;"
            >
              <!-- <span class="enclosure">{{ item.fileName || "--" }}</span> -->
              
              <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
              <!-- <el-button
                type="text"
                class="see"
                @click="
                  () => {
                    download(item.fileUrl);
                  }
                "
                >查看</el-button> -->
                <el-button
                type="text"
                class="down"
                @click="
                  () => {
                    download(item.fileUrl);
                  }
                "
                >下载</el-button
              >
            </div>
          </div>
        </div>
        <div v-show="details.invoiceName">
          <div class="two">开票信息</div>
          <div>
            <span class="one-tit">发票类型：</span
            >{{ details.invoiceType == "common" ? "普通" : "专票" || "--" }}
          </div>
          <div>
            <span class="one-tit">名称：</span>{{ details.invoiceName || "--" }}
          </div>
          <div>
            <span class="one-tit">纳税人识别号：</span
            >{{ details.invoiceTaxpayerIdentificationNumber || "--" }}
          </div>
          <div>
            <span class="one-tit">地址、电话：</span
            >{{ details.invoicePhone || "--" }}
          </div>
          <div>
            <span class="one-tit one-bottom">开户行及账号：</span
            >{{ details.invoiceAccount || "--" }}
          </div>
          <div class="two">收件信息</div>
          <div>
            <span class="one-tit">收件人：</span
            >{{ details.transactionName || "--" }}
          </div>
          <div>
            <span class="one-tit">手机号：</span
            >{{ details.transactionPhone || "--" }}
          </div>
          <div>
            <span class="one-tit">收件地址：</span
            >{{ details.invoiceAddress || "--" }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 到账时间 -->
    <el-dialog
      v-dialogDrag
      title="财务确认"
      :visible.sync="dateDialog"
      width="30%"
      :append-to-body="true"
      :before-close="canelTime"
    >
      <div>
        <span class="time-date">到账时间:</span>
        <el-date-picker
          v-model="value1"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="canelTime"
          >取 消</el-button
        >
        <el-button class="common-screen-btn" type="primary" @click="confirmTime"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { tableColumn, Config, formatDateTimer,DateTransform } from "../../../utils/index.js";
import { getOrderDetail,orderChangePrice } from "../../../service/customer.js";
import { upload } from "../../../service/upload.js";
import {
  getIncomeDetail,
  getDetail,
  confirmMoney,
  selectIncomeDetail,
  updateFinancialFlow,
  orderWorkLog,
  fileDelete,
  fileUpload
} from "../../../service/money";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue";
import CommonPicture from "../../../components/common/commonPicture.vue";
import { workLog } from "../../../service/common.js";
export default {
  components: { CommonWorkLog, CommonPicture },
  props: [
    "drawer",
    // "tableRow", //传过来一行数据
    "customerInfo",
    "chanceFollowList",
    "tableRow2",
    "loading"
  ],
  data() {
    return {
      formatDateTimer,
      tableColumn,
      Config,
      DateTransform,
      activeName: "about", // tabs
      recordRadio: 1, // 跟进记录radio
      recordContent: "", // 跟进记录内容
      tableData: [],
      tableData2: [],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      planTask: [], // 提醒列表
      params: {
        page: 1,
        pageSize: 5
      },
      rolesCompanyId: this.$sto.get(Config.constants.userInfo).company_id,
      tableRow: {},
      details: {},
      //////////////////////////////////////弹框
      dialogVisible: false,
      adminInfo: "",
      OrderInfo: "",
      fileList: [],
      fileList2: [],//平台文件
      followRecordList: [], // 工作日志
      dateDialog: false,
      // value1: DateTransform(new Date()),
      value1: "",
      params2: {},
      platInfo:{},
      platform: "", //判断
      platCompanyId: null, //平台进账类型的公司ID
      changeTableData: [], //订单调价列表
      changeParams: { //订单调价列表
        pageNum: 1,
        pageSize: 10
      },
      loadingOrder: false,
      paginationChange:{ //订单调价列表
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      activeTab: "1",
      
    };
  },
  methods: {
    // 平台删除附件
    async deleteFile(item) {
      this.$emit("loadFun", true);
      let params = {
        filedId: item.filedId,
        filedUrl: item.filedUrl,
        fileName: item.filedName
      };
      params.disOrderNo = this.tableRow.id
      const res = await fileDelete(params);
      if (res.code != 200) {
       return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.platWorkLog(this.tableRow.id)
      this.getIncomeDetailList2(this.tableRow);
      this.$emit("loadFun", false);
    },
    download(url) {
      // 下载
      window.location.href = url;
    },
    handleClose(done) {
      this.platform = ""
      this.activeTab = "1"
      this.paginationChange.total = 0
      this.tableData = []
      this.tableData2 = []
      this.$emit("handleClose", done);
    },
    // 平台上传
    async uploadFile(e) {
      // 上传合同
      this.$emit("loadFun", true);
      let file = e.target.files[0];
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.$emit("loadFun", false);
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.$emit("loadFun", false);
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      // 上传接口
      const res = await fileUpload({
        disOrderNo: this.tableRow.id,
        filedId: resData.id, 
        filedUrl: resData.filename, 
        fileName: fileName
      });
      if (res.code != 200) {
        return this.$message.success("上传失败");
      }
      this.$message.success("上传成功");
      e.target.value = "";
      this.getIncomeDetailList2(this.tableRow);
      this.platWorkLog(this.tableRow.id)
      this.$emit("loadFun", false);
    },
    incomeInfo(row) {
      console.log(row);
      this.dialogVisible = true;
      this.getDetailList(row);
    },
    // 确认到账
    conIncome(row) {
      if(this.platform != '平台派单'){
        this.dateDialog = true;

        let flowId = row.id;

        this.params2.flowId = flowId;
      }else{// 平台确认到账
        this.id = row.id
        this.confirmGet()
      }

    },
    canelTime(){
      this.dateDialog = false;
      this.value1 = "";
    },
    // 确认时间
    async confirmTime() {
      let params2 = { ...this.params2 };
      params2.confirmDate = this.value1;
      if (!params2.confirmDate) {
        delete params2.confirmDate;
      }
      const res = await confirmMoney(params2);
      if (res.code != 200) {
       return this.$message.error("操作失败！");
      }
      this.$emit("updateChild2", this.tableRow.id);
      this.$message.success("操作成功！");
      this.dateDialog = false;
      this.value1 = "";
      this.getIncomeDetailList(this.tableRow);
      
    },
    // 确认到账
    async confirmGet(){
      if(this.platCompanyId != this.rolesCompanyId){
        return this.$message.error("您无法操作其他公司到账");
      }else{
        const res = await updateFinancialFlow({id: this.id})
        if(res.code != 200) {
          return this.$message.error("操作失败！");
        }
        this.$emit("updateChild2", this.tableRow.id);//刷父列表
        this.$message.success("操作成功！");
        this.getIncomeDetailList2(this.tableRow);
        this.platWorkLog(this.tableRow.id)
      }
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getIncomeDetailList(this.tableRow);
    },
    async getIncomeDetailList(row) {
      this.tableRow = row;
      let params = { ...this.params };
      params.orderId = row.id;
      const { data } = await getIncomeDetail(params);
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.tableData = data.list;
    },
    async getIncomeDetailList2(row) {
      this.platform = '平台派单'
      this.tableRow = row;
      let params = { ...this.params };
      params.disOrderNo = row.id;
      delete params.page
      delete params.pageSize
      const { data } = await selectIncomeDetail(params);
      this.platInfo = data
      this.tableData2 = data.finList;
      this.fileList2 = data.fileList
      this.platCompanyId = data.companyId
    },
    async getDetailList(row) {
      let flowId = row.id;
      const { data } = await getDetail({ flowId: flowId });
      this.details = data;
      console.log(data, "进账流水信息详情");
    },
    // 基本信息一栏
    async getOrderDetail(row) {
      this.$emit("loadFun", true);
      let id = row.id;
      let resData = (await getOrderDetail({ orderId: id })).data;
      this.adminInfo = resData.admin;
      this.OrderInfo = resData.customerOrderDetailVo;
      if (resData && resData.customerOrderDetailVo) {
        let filedIdsArr = resData.customerOrderDetailVo.filedIds
          ? resData.customerOrderDetailVo.filedIds.split(",")
          : [];
        let filedNameArr = resData.customerOrderDetailVo.filedName
          ? resData.customerOrderDetailVo.filedName.split(",")
          : [];
        let filedUrlArr = resData.customerOrderDetailVo.filedUrl
          ? resData.customerOrderDetailVo.filedUrl.split(",")
          : [];
        let newArrFile = [];
        filedIdsArr.map((item, index) => {
          newArrFile.push({
            name: filedNameArr[index],
            id: item,
            url: filedUrlArr[index]
          });
        });
        this.fileList = newArrFile;
      }

      this.$emit("loadFun", false);
    },
    async workLog(id) {
      // 工作日志
      let resData = (await workLog({ id: id, type: 1 })).data;
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = {};
          }
        });
      }
      this.followRecordList = resData;
    },
    // 平台工作纪律
    async platWorkLog(id) {
      // 工作日志
      let resData = (await orderWorkLog({ realtionId: id})).data;
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = [];
          }
        });
      }
      this.followRecordList = resData;

    },
    async orderChangePrice(id) { // 订单调价列表
      let changeParams = { ...this.changeParams };
      changeParams.orderId = id
      this.loadingOrder = true
      let { data } = await orderChangePrice(changeParams)
      this.loadingOrder = false
      this.changeTableData = data.list || []
      this.paginationChange.total = data.total || 0
    },
    pageFunChange(e, type) { // 分页
      if (type == "pageSize") {
        this.changeParams.pageSize = e;
      } else {
        this.changeParams.pageNum = e;
      }
      this.orderChangePrice(this.tableRow.id);
    },
    handleClick(tab) {
      if(tab.name == "4"){
        this.orderChangePrice(this.tableRow.id);
      }
    },
    openNewWin(url) {
      window.open(url);
    },
  }
};
</script>
<style lang="less" scoped>
.seaDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .rightTop {
        border-bottom: 1px #eaeaeb solid;
        padding: 24px;
        font-size: 14px;
        color: #101010;
        .visitIcon {
          width: 14px;
          vertical-align: middle;
          margin-right: 8px;
        }
        .notContent {
          margin-top: 24px;
        }
      }
      /deep/ .el-tabs__content {
        padding-left: 16px;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .itemBtn {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 14px;
            border: 1px solid #1890ff;
            border-radius: 4px;
            font-size: 14px;
            color: #101010;
            margin: 16px 16px 8px 0;
          }
          .item-detail {
            color: #999;
            margin-top: 8px;
            vertical-align: middle;
            .visitIcon {
              width: 14px;
              margin-left: 8px;
            }
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail-tit-info {
        font-size: 18px;
      }
      .hoverEdit {
        display: inline-block;
        width: 333px;
        font-size: 18px;
        padding: 6px 12px;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 303px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input {
        width: 220px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
        .cancel {
          cursor: pointer;
        }
        /deep/ .el-input__inner,
        .el-input-group__append {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input-group__append {
          height: 30px;
          line-height: 30px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-top: 16px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
          }
        }
      }
    }
    .data-content {
      .detail-content {
        margin-top: 16px;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
.dialog-input {
  width: 320px !important;
}
.data-title {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #000000a6;
  border-bottom: 1px dashed #eaeaeb;
  margin-top: 16px;
}
.first {
  padding-bottom: 50px;
  border-bottom: 1px solid #eaeaeb;
  .title {
    margin-bottom: 7px;
    .first-tit {
      font-size: 18px;
    }
  }

  .tit-btn {
    display: inline-block;
    width: 93px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 11px;
  }
}
.con-margin {
  display: inline-block;
  // margin-right: 68px;
  width: 268px;
  color: #666;
  font-size: 14px;
}
.first-con1,
.first-con2,
.first-con3 {
  margin-top: 16px;
}
.first-con3 {
  .con-margin {
    display: inline-block;
    // margin-right: 68px;
    width: 100%;
    color: #666;
    font-size: 14px;
  }
}
.amount {
  color: #f09009;
  font-size: 14px;
}
.money {
  box-sizing: border-box;
  display: inline-block;
  // width: 82px;
  padding: 4px 6px;
  line-height: 16px;
  font-size: 14px;
  text-align: center;
  color: #f98787;
  border: 1px solid #f98787;
  border-radius: 4px;
}
.money2 {
  box-sizing: border-box;
  display: inline-block;
  // width: 82px;
  padding: 4px 6px;
  line-height: 16px;
  font-size: 14px;
  text-align: center;
  color: #f09009;
  border: 1px solid #f09009;
  border-radius: 4px;
}
.money3 {
  box-sizing: border-box;
  display: inline-block;
  // width: 60px;
  padding: 4px 6px;
  line-height: 16px;
  font-size: 14px;
  text-align: center;
  color: #66CC1F;
  border: 1px solid #66CC1F;
  border-radius: 4px;
}
.baseinfo {
  display: inline-block;
  width: 422px;
  margin-top: 16px;
  color: #666;
  font-size: 12px;
}
.upload-container {
  margin-top: 24px;
  .tip {
      font-size: 12px;
    }
    .upload-title {
      font-size: 18px;
      color: #101010;
    }
    .uploadBtn-container {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #1890ff;
      color: #1890ff;
      font-size: 14px;
      background: #fff;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      margin-left: 16px;
      position: relative;
      .uploadBtn-input {
        width: 100px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        font-size: 0;
      }
    }
  .filesList-container {
    border: 1px solid #ebeef5;
    margin-top: 12px;
    border-radius: 4px;
    .fileItem {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      border-bottom: 1px solid #ebeef5;
      height: 74px;
      line-height: 74px;
      align-items: center;
      .fileItem-btn-Container {
        display: flex;
        .fileItemBtn {
          margin-right: 16px;
          color: #1890ff;
          cursor: pointer;
        }
      }
    }
  }
  .upload-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    .tip {
      font-size: 12px;
    }
    .upload-title {
      font-size: 18px;
      color: #101010;
    }
    .uploadBtn-container {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #666;
      font-size: 14px;
      background: #fff;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      margin-left: 16px;
      position: relative;
      .uploadBtn-input {
        width: 100px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
}
.one-tit {
  display: inline-block;
  width: 120px;
  margin-top: 24px;
}
.one-tit2 {
  display: inline-block;
  width: 120px !important;
  margin-top: 24px;
}
.filelist{
  width: 380px;
}
.one-unit {
  margin-left: 16px;
}
.enclosure {
  display: inline-block;
  width: 309px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.one-bottom {
  margin-bottom: 24px;
}
.box {
  display: inline-block;
}
.two {
  padding-top: 15px;
  border-top: 1px solid #e6e6e6;
}
/deep/.el-dialog__body {
  padding: 20px 32px;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/deep/.el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
  height: 0;
}
.time-date {
  margin-right: 25px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 80%;
}
/deep/ .el-button {
  width: 88px;
}
.btned {
  color: #1890ff !important;
  border: 1px solid #1890ff !important;
  border-radius: 4px !important;
}
</style>
